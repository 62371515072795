<template>
  <v-card class="mb-2">
    <v-card-title>ConfirmDialog</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          ><v-btn @click="show" outlined text>show</v-btn><br />result:
          {{ result }}</v-col
        >
        <v-col>
          <v-text-field
            type="text"
            label="message"
            outlined
            v-model="message"
          ></v-text-field>
          <v-text-field
            type="text"
            label="icon"
            outlined
            v-model="icon"
          ></v-text-field>
          <LookupValueInput
            outlined
            label="Farbe"
            v-model="selectedColor"
            :items="colors"
          />
        </v-col>
      </v-row>

      <v-alert dark>
        <pre>
this.result = await this.$root.confirm({message:'{{ message }}', color:'{{
            color
          }}', icon:'{{ icon }}'});</pre
        >
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import LookupValueInput from 'common/components/LookupValueInput.vue';
export default {
  name: 'ConfirmDialogDemo',
  components: { LookupValueInput },
  data() {
    return {
      title: 'löschen',
      message: 'bist du sicher?',
      color: 'primary',
      selectedColor: {},
      icon: 'mdi-information',
      result: false,
      colors: [
        { id: 1, code: 'primary', description: 'Gymer-Blau' },
        { id: 2, code: 'info', description: 'Unterricht' },
        { id: 3, code: 'success', description: 'Organisation' },
        { id: 4, code: 'error', description: 'Menschen' },
        { id: 5, code: 'warning', description: 'myGymer' },
      ],
    };
  },
  watch: {
    selectedColor() {
      this.color = this.selectedColor.code;
    },
  },
  methods: {
    async show() {
      this.result = await this.$root.confirm({
        message: this.message,
        color: this.color,
        icon: this.icon,
      });
    },
  },
};
</script>
